import { template as template_7fe8a8d809e2447b808012ef330a31df } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_7fe8a8d809e2447b808012ef330a31df(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
