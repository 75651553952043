import { template as template_81b693efbde2438f81031bca3d64886e } from "@ember/template-compiler";
const FKControlMenuContainer = template_81b693efbde2438f81031bca3d64886e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
