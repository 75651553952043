import { template as template_4c7a057eef324b57a5c2513ca28fd2c2 } from "@ember/template-compiler";
const FKText = template_4c7a057eef324b57a5c2513ca28fd2c2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
