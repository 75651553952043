import { template as template_38f70db97b9c4b2fb8521c34a83adab6 } from "@ember/template-compiler";
const FKLabel = template_38f70db97b9c4b2fb8521c34a83adab6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
